<template>
  <div class="optimized" v-if="!isLoading && state.route_geo_json?.properties">

    <div class="optimized-top" >
      <div>
        <a-row>
          <a-col style="width: 50%;">
            <div class="header">
              <div class="header-heading">
                <router-link class="header-back" :to="{ name: 'Fleet', params: { imo: state.imo, tab: 'route-optimizer', filter:( route.params.id ? 'optimized-route-history' : 'route-for-optimize')} }">
                  <img :src="icons.arrowLeft" alt="arrow" />
                </router-link>
                <div>
                  <a-typography-title :level="2">{{$t('fleet-page.optimization results')}}</a-typography-title>
                </div>
              </div>
            </div>        
            <div class="destination" >
              <a-row style="height: 100%;">
                <a-col style="align-content: center; height: 100%; width: 32px;">
                  <a-typography-text type="secondary" style="font-weight: 600; margin-left: 12px;">
                    A:
                  </a-typography-text>
                </a-col>
                <a-col >
                  <a-row style="height: 60%; width: 260px;">
                    <a-col style="align-content: end;">
                      <a-typography-text type="primary" style="font-weight: 600;">  
                        {{ state.departure_port }}
                      </a-typography-text>
                    </a-col>
                  </a-row>
                  <a-row style="height: 40%; width: 260px;">
                    <a-col>
                      <a-typography-text type="secondary" style="font-weight: 300;">
                        {{ "ETD: " + (state.etd != null? (date.formatUTC(state.etd) + " UTC" ) : "") }}
                      </a-typography-text>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col style="width: 110px; height: 100%">
                  <a-row style="height: 100%;">
                    <a-col>
                      <svg width="110" height="58" viewBox="0 0 110 58" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <path d="M0 0H95L110 29L95 58H0L15 29.0L0 0Z" fill="#379BFF" data-v-9a97b532="">
                        </path>
                        <text x="50%" y="35%" dominant-baseline="middle" text-anchor="middle" fill="#27282c" >
                          {{ state.distance + " " + $t('units.nm') }}
                        </text>
                        <text x="48%" y="75%" dominant-baseline="middle" text-anchor="middle" fill="#27282c" >
                          {{ state.waypoints + " " + $t('fleet-page.WPT') }}
                        </text>
                      </svg>
                    </a-col>
                  </a-row>      
                </a-col>          
                <a-col style="align-content: center; height: 100%; width: 32px;">
                  <a-typography-text type="secondary" style="font-weight: 600; margin-left: 12px;">
                    B:
                  </a-typography-text>
                </a-col>
                <a-col>
                  <a-row style="height: 60%; width: 260px;">
                    <a-col style="align-content: end;">
                      <a-typography-text type="primary" style="font-weight: 600;">
                        {{ state.arrival_port }}
                      </a-typography-text>
                    </a-col>
                  </a-row>
                  <a-row style="height: 40%; width: 260px;">
                    <a-col >
                      <a-typography-text type="secondary" style="font-weight: 300;">
                        {{ "ETA: " + (state.eta != null? (date.formatUTC(state.eta) + " UTC" ) : "") }}
                      </a-typography-text>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
            <div style="margin-bottom: 15px; margin-left: 30px">
              <a-row>
                <a-col>
                  <div style="width: 300px;">
                    <a-typography-text type="secondary" style="width: 50%; display: inline-flex;">
                      {{$t('fleet-page.vessel condition')}}
                    </a-typography-text>                    
                    <a-typography-text type="primary" style=" width: 50%; margin-left: 20px; display: contents; font-weight: 600;" v-if="state.load_condition === 'LADEN'">
                      {{$t('fleet-page.laden')}}
                    </a-typography-text>
                    <a-typography-text type="primary" style=" width: 50%; margin-left: 20px; display: contents; font-weight: 600;" v-if="state.load_condition === 'BALLAST'">
                      {{$t('fleet-page.ballast')}}
                    </a-typography-text>
                    <!-- <a-typography-text type="primary" style=" width: 50%; margin-left: 20px; display: contents; font-weight: 600;">
                      {{ state.load_condition }}
                    </a-typography-text> -->
                  </div>
                </a-col>
              </a-row>
            </div>

            <div style="width: 700px; height: 100px; margin-left: 30px;">
              <a-row>
                <a-col style="width: 110px; border-bottom-color: lightslategrey; border-bottom-style: dashed; border-bottom-width: 1px;">
                  <div>              
                  </div>
                </a-col>
                <a-col style="width: 180px; border-left-color: lightslategrey;border-left-style: dashed;border-left-width: 1px;padding-left: 5px;border-bottom-color: lightslategrey;border-bottom-style: dashed;border-bottom-width: 1px;">         
                  <a-typography-text type="secondary" style="width: 150px; display: inline-block;">
                    {{$t('fleet-page.const RPM')}}
                  </a-typography-text>
                  <a-dropdown placement="bottomRight">
                    <a @click.prevent>
                      <img :src="icons.download" alt="download" />
                    </a>
                    <template #overlay>
                      <a-menu>
                        <a-menu-item key="0">
                          <a href="" @click.prevent="exportOptimized(0)">
                            <FileOutlined style="margin-right: 5px;"/>
                            {{ $t('fleet-page.download file') }}
                          </a>
                        </a-menu-item>
                        <a-menu-item key="1">
                          <a href="" @click.prevent="saveOptimized('const_rpm')">
                            <CloudUploadOutlined style="margin-right: 5px;"/>
                            {{ $t('fleet-page.upload to server') }}
                          </a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </a-col>
                <a-col style="width: 180px; border-left-color: lightslategrey;border-left-style: dashed;border-left-width: 1px;padding-left: 5px;border-bottom-color: lightslategrey;border-bottom-style: dashed;border-bottom-width: 1px;">
                  <a-typography-text type="secondary"  style="width: 150px; display: inline-block;">
                    {{$t('fleet-page.const power')}}
                  </a-typography-text>
                  <a-dropdown placement="bottomRight">
                    <a @click.prevent>
                      <img :src="icons.download" alt="download" />
                    </a>
                    <template #overlay>
                      <a-menu>
                        <a-menu-item key="0">
                          <a href="" @click.prevent="exportOptimized(1)">
                            <FileOutlined style="margin-right: 5px;"/>
                            {{ $t('fleet-page.download file') }}
                          </a>
                        </a-menu-item>
                        <a-menu-item key="1">
                          <a href="" @click.prevent="saveOptimized('const_power')">
                            <CloudUploadOutlined style="margin-right: 5px;"/>
                            {{ $t('fleet-page.upload to server') }}
                          </a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </a-col>
                <a-col style="width: 180px;border-left-color: lightslategrey;border-left-style: dashed;border-left-width: 1px;padding-left: 5px;border-bottom-color: lightslategrey;border-bottom-style: dashed;border-bottom-width: 1px;">
                  <a-typography-text type="secondary"  style="width: 150px; display: inline-block;">
                    {{$t('fleet-page.const speed')}}
                  </a-typography-text>
                  <a-dropdown placement="bottomRight">
                    <a @click.prevent>
                      <img :src="icons.download" alt="download" />
                    </a>
                    <template #overlay>
                      <a-menu>
                        <a-menu-item key="0">
                          <a href="" @click.prevent="exportOptimized(2)">
                            <FileOutlined style="margin-right: 5px;"/>
                            {{ $t('fleet-page.download file') }}
                          </a>
                        </a-menu-item>
                        <a-menu-item key="1">
                          <a href="" @click.prevent="saveOptimized('const_speed')">
                            <CloudUploadOutlined style="margin-right: 5px;"/>
                            {{ $t('fleet-page.upload to server') }}
                          </a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </a-col>
              </a-row>
              <a-row>
                <a-col style="width: 110px;">
                  <a-typography-text type="secondary">
                    {{$t('fleet-page.fuel cons')}}
                  </a-typography-text>
                </a-col>
                <a-col style="width: 180px;border-left-color: lightslategrey;border-left-style: dashed;border-left-width: 1px;padding-left: 5px;">        
                  <a-typography-text type="primary" style="font-weight: 600;">
                    {{ state.route_geo_json.properties.const_rpm.fuel_consumption + " " + $t('units.mts') + ' (' + state.route_geo_json.properties.const_rpm.fuel_consumption_pc + '%)' }}
                  </a-typography-text>
                </a-col>
                <a-col style="width: 180px;border-left-color: lightslategrey;border-left-style: dashed;border-left-width: 1px;padding-left: 5px;">        
                  <a-typography-text type="primary" style="font-weight: 600;">
                    {{ state.route_geo_json.properties.const_power.fuel_consumption + " " + $t('units.mts') + ' (' + state.route_geo_json.properties.const_power.fuel_consumption_pc + '%)' }}
                  </a-typography-text>
                </a-col>
                <a-col style="width: 180px;border-left-color: lightslategrey;border-left-style: dashed;border-left-width: 1px;padding-left: 5px;">
                  <a-typography-text type="primary" style="font-weight: 600;">
                    {{ state.route_geo_json.properties.const_speed.fuel_consumption + " " + $t('units.mts') + ' (' + state.route_geo_json.properties.const_speed.fuel_consumption_pc + '%)' }}
                  </a-typography-text>
                </a-col>
              </a-row>
              <a-row>
                <a-col style="width: 110px;">
                  <a-typography-text type="secondary">
                    {{$t('fleet-page.CII')}}
                  </a-typography-text>
                </a-col>
                <a-col style="width: 180px;border-left-color: lightslategrey;border-left-style: dashed;border-left-width: 1px;padding-left: 5px;">
                  <a-typography-text type="primary" style="font-weight: 600;">
                    {{ state.route_geo_json.properties.const_rpm.CII }}
                  </a-typography-text>
                </a-col>
                <a-col style="width: 180px;border-left-color: lightslategrey;border-left-style: dashed;border-left-width: 1px;padding-left: 5px;">
                  <a-typography-text type="primary" style="font-weight: 600;">
                    {{ state.route_geo_json.properties.const_power.CII }}
                  </a-typography-text>
                </a-col>
                <a-col style="width: 180px;border-left-color: lightslategrey;border-left-style: dashed;border-left-width: 1px;padding-left: 5px;">
                  <a-typography-text type="primary" style="font-weight: 600;">
                    {{ state.route_geo_json.properties.const_speed.CII }}
                  </a-typography-text>
                </a-col>
              </a-row>
              <a-row>
                <a-col style="width: 110px;">
                  <a-typography-text type="secondary">
                    {{$t('fleet-page.time at sea')}}
                  </a-typography-text>
                </a-col>
                <a-col style="width: 180px;border-left-color: lightslategrey;border-left-style: dashed;border-left-width: 1px;padding-left: 5px;">
                  <a-typography-text type="primary" style="font-weight: 600;">
                    {{ date.formatDateFromMinutes(state.route_geo_json.properties.const_rpm.duration) }}
                  </a-typography-text>
                </a-col>
                <a-col style="width: 180px;border-left-color: lightslategrey;border-left-style: dashed;border-left-width: 1px;padding-left: 5px;">
                  <a-typography-text type="primary" style="font-weight: 600;">
                    {{ date.formatDateFromMinutes(state.route_geo_json.properties.const_power.duration) }}
                  </a-typography-text>
                </a-col>
                <a-col style="width: 180px;border-left-color: lightslategrey;border-left-style: dashed;border-left-width: 1px;padding-left: 5px;">
                  <a-typography-text type="primary" style="font-weight: 600;">
                    {{ date.formatDateFromMinutes(state.route_geo_json.properties.const_speed.duration) }}
                  </a-typography-text>
                </a-col>
              </a-row>
            </div> 
          </a-col>
          <a-col style="width: 50%;">
            <div>
              <vue-echarts
                  ref="chartRef"
                  :option="chartOption"
                  :style="`height: 255px`"
                  autoresize
              />
            </div>
          </a-col>
        </a-row>     
      </div>    
      <div class="optimized-map">
        <div id="rtmapcontainer" />
      </div>     
    </div>
    <div class="optimized-bottom" >

      <a-tabs >
        <a-tab-pane key="const-rpm" :tab="$t('fleet-page.const RPM')" >
          <div class="table"  v-if="!isLoading" >
            <a-table style="font-size: 12px"
                class="table-src-route"
                :columns="columns"
                :scroll="{ x: '100%', y: 'calc(100vh - 535px)' }"
                :data-source="dataSourceConstRpm.list"
                :pagination="false"
            >
              <template #headerCell="{ column }">
                <template v-if="column.key === 'id'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'name'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'coords'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'eta'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'rpm'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'speed'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'wind'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'swave'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'scurrent'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
              </template>

              <template #bodyCell="{ column, record }">
                <template v-if="column.dataIndex === 'id'">
                  <a-typography-text type="secondary">
                    {{ record.value.id }}
                  </a-typography-text>
                </template>

                <template v-if="column.dataIndex === 'name'">
                  <a-typography-text v-if="record.value.name" type="primary">
                    {{ record.value.name }}
                  </a-typography-text>
                  <a-typography-text v-else type="secondary">
                    Undefined
                  </a-typography-text>
                </template>

                <template v-if="column.dataIndex === 'coords'">
                  <a-typography-text type="primary">
                    {{ kFormatter.formatCoordinatesLat(record.value.point_data.geometry.coordinates[1]) }}
                  </a-typography-text>
                  <br/>
                  <a-typography-text type="primary">
                    {{ kFormatter.formatCoordinatesLon(record.value.point_data.geometry.coordinates[0]) }}
                  </a-typography-text>
                </template>

                <template v-if="column.dataIndex === 'eta'">
                  <a-typography-text type="primary">
                    {{  date.formatUTC(record.value.point_data.properties.eta) + " UTC"}}
                  </a-typography-text>
                </template>
                <template v-if="column.dataIndex === 'rpm'">
                  <a-typography-text type="primary">
                    {{ record.value.point_data.properties.rpm }}
                  </a-typography-text>
                </template>
                <template v-if="column.dataIndex === 'speed'">
                  <a-typography-text type="primary">
                    {{ record.value.point_data.properties.speed }}
                  </a-typography-text>
                </template>
                <template v-if="column.dataIndex === 'wind'">
                  <img :src="icons.arrowWind" alt="arrowWind" :style="rotate(record.value.point_data.properties.weather.wind.relative_direction) + '; margin-right: 5px; margin-bottom: 7px;'"/>
                  <a-typography-text type="primary">
                    {{ Math.round(record.value.point_data.properties.weather.wind.true_direction * 10)/ 10 + "° " +  Math.round(record.value.point_data.properties.weather.wind.true_speed * 10)/ 10 + " m/s" }}
                  </a-typography-text>
                    <a-tooltip>
                      <template #title>{{ record.value.point_data.properties.weather.wind.tooltip }}</template>
                      <img :src="icons.warningYellow" alt="warningYellow" v-if="record.value.point_data.properties.weather.wind.dangerous == 1" style="margin-left: 10px; margin-bottom: 2px;"/>
                      <img :src="icons.warningRed" alt="warningRed" v-if="record.value.point_data.properties.weather.wind.dangerous == 2" style="margin-left: 10px; margin-bottom: 2px;"/>
                    </a-tooltip>                  
                </template>
                <template v-if="column.dataIndex === 'swave'">
                  <img :src="icons.arrowWind" alt="arrowWind" :style="rotate(record.value.point_data.properties.weather.wave.relative_direction) + '; margin-right: 5px; margin-bottom: 7px;'"/>
                  <a-typography-text type="primary">
                    {{ Math.round(record.value.point_data.properties.weather.wave.true_direction * 10)/ 10 + "° " +  Math.round(record.value.point_data.properties.weather.wave.height * 10)/ 10 + " m" }}
                  </a-typography-text>
                  <a-tooltip>
                      <template #title>{{ record.value.point_data.properties.weather.wave.tooltip }}</template>
                      <img :src="icons.warningYellow" alt="warningYellow" v-if="record.value.point_data.properties.weather.wave.dangerous == 1" style="margin-left: 10px; margin-bottom: 2px;"/>
                      <img :src="icons.warningRed" alt="warningRed" v-if="record.value.point_data.properties.weather.wave.dangerous == 2" style="margin-left: 10px; margin-bottom: 2px;"/>
                  </a-tooltip>                  
                </template>
                <template v-if="column.dataIndex === 'scurrent'">
                  <img :src="icons.arrowWind" alt="arrowWind" :style="rotate(record.value.point_data.properties.weather.current.relative_direction) + '; margin-right: 5px; margin-bottom: 7px;'"/>
                  <a-typography-text type="primary">
                    {{ Math.round(record.value.point_data.properties.weather.current.true_direction * 10)/ 10 + "° " +  Math.round(record.value.point_data.properties.weather.current.true_speed * 10)/ 10 + "kn" }}
                    <a-tooltip>
                      <template #title>{{ record.value.point_data.properties.weather.current.tooltip }}</template>
                      <img :src="icons.warningYellow" alt="warningYellow" v-if="record.value.point_data.properties.weather.current.dangerous == 1" style="margin-left: 10px; margin-bottom: 2px;"/>
                      <img :src="icons.warningRed" alt="warningRed" v-if="record.value.point_data.properties.weather.current.dangerous == 2" style="margin-left: 10px; margin-bottom: 2px;"/>
                    </a-tooltip>                  
                  </a-typography-text>
                </template>

                </template>
            </a-table>
          </div>
          <a-skeleton active class="loading" v-else />
          <div style="width: 100%; height: 300px; background-color: #FFFFFF; position: relative;"></div>
        </a-tab-pane>
        <a-tab-pane key="const-power" :tab="$t('fleet-page.const power')"  >
          <div class="table"  v-if="!isLoading" >
            <a-table style="font-size: 12px"
                     class="table-src-route"
                     :columns="columns"
                     :scroll="{ x: '100%', y: 'calc(100vh - 535px)' }"
                     :data-source="dataSourceConstPower.list"
                     :pagination="false"
            >
              <template #headerCell="{ column }">
                <template v-if="column.key === 'id'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'name'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'coords'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'eta'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'rpm'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'speed'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'wind'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'swave'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'scurrent'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
              </template>

              <template #bodyCell="{ column, record }">
                <template v-if="column.dataIndex === 'id'">
                  <a-typography-text type="secondary">
                    {{ record.value.id }}
                  </a-typography-text>
                </template>

                <template v-if="column.dataIndex === 'name'">
                  <a-typography-text v-if="record.value.name" type="primary">
                    {{ record.value.name }}
                  </a-typography-text>
                  <a-typography-text v-else type="secondary">
                    Undefined
                  </a-typography-text>
                </template>

                <template v-if="column.dataIndex === 'coords'">
                  <a-typography-text type="primary">
                    {{ kFormatter.formatCoordinatesLat(record.value.point_data.geometry.coordinates[1])}}
                  </a-typography-text>
                  <br/>
                  <a-typography-text type="primary">
                    {{ kFormatter.formatCoordinatesLon(record.value.point_data.geometry.coordinates[0])}}
                  </a-typography-text>
                </template>

                <template v-if="column.dataIndex === 'eta'">
                  <a-typography-text type="primary">
                    {{  date.formatUTC(record.value.point_data.properties.eta) + " UTC"}}
                  </a-typography-text>
                </template>
                <template v-if="column.dataIndex === 'rpm'">
                  <a-typography-text type="primary">
                    {{ record.value.point_data.properties.rpm}}
                  </a-typography-text>
                </template>
                <template v-if="column.dataIndex === 'speed'">
                  <a-typography-text type="primary">
                    {{ record.value.point_data.properties.speed }}
                  </a-typography-text>
                </template>
                <template v-if="column.dataIndex === 'wind'">
                  <img :src="icons.arrowWind" alt="arrowWind" :style="rotate(record.value.point_data.properties.weather.wind.relative_direction) + '; margin-right: 5px; margin-bottom: 7px;'"/>
                  <a-typography-text type="primary">
                    {{ Math.round(record.value.point_data.properties.weather.wind.true_direction * 10)/ 10 + "° " +  Math.round(record.value.point_data.properties.weather.wind.true_speed * 10)/ 10 + " m/s" }}
                  </a-typography-text>
                    <a-tooltip>
                      <template #title>{{ record.value.point_data.properties.weather.wind.tooltip }}</template>
                      <img :src="icons.warningYellow" alt="warningYellow" v-if="record.value.point_data.properties.weather.wind.dangerous == 1" style="margin-left: 10px; margin-bottom: 2px;"/>
                      <img :src="icons.warningRed" alt="warningRed" v-if="record.value.point_data.properties.weather.wind.dangerous == 2" style="margin-left: 10px; margin-bottom: 2px;"/>
                    </a-tooltip>                  
                </template>
                <template v-if="column.dataIndex === 'swave'">
                  <img :src="icons.arrowWind" alt="arrowWind" :style="rotate(record.value.point_data.properties.weather.wave.relative_direction) + '; margin-right: 5px; margin-bottom: 7px;'"/>
                  <a-typography-text type="primary">
                    {{ Math.round(record.value.point_data.properties.weather.wave.true_direction * 10)/ 10 + "° " +  Math.round(record.value.point_data.properties.weather.wave.height * 10)/ 10 + " m" }}
                  </a-typography-text>
                  <a-tooltip>
                      <template #title>{{ record.value.point_data.properties.weather.wave.tooltip }}</template>
                      <img :src="icons.warningYellow" alt="warningYellow" v-if="record.value.point_data.properties.weather.wave.dangerous == 1" style="margin-left: 10px; margin-bottom: 2px;"/>
                      <img :src="icons.warningRed" alt="warningRed" v-if="record.value.point_data.properties.weather.wave.dangerous == 2" style="margin-left: 10px; margin-bottom: 2px;"/>
                  </a-tooltip>                  
                </template>
                <template v-if="column.dataIndex === 'scurrent'">
                  <img :src="icons.arrowWind" alt="arrowWind" :style="rotate(record.value.point_data.properties.weather.current.relative_direction) + '; margin-right: 5px; margin-bottom: 7px;'"/>
                  <a-typography-text type="primary">
                    {{ Math.round(record.value.point_data.properties.weather.current.true_direction * 10)/ 10 + "° " +  Math.round(record.value.point_data.properties.weather.current.true_speed * 10)/ 10 + "kn" }}
                    <a-tooltip>
                      <template #title>{{ record.value.point_data.properties.weather.current.tooltip }}</template>
                      <img :src="icons.warningYellow" alt="warningYellow" v-if="record.value.point_data.properties.weather.current.dangerous == 1" style="margin-left: 10px; margin-bottom: 2px;"/>
                      <img :src="icons.warningRed" alt="warningRed" v-if="record.value.point_data.properties.weather.current.dangerous == 2" style="margin-left: 10px; margin-bottom: 2px;"/>
                    </a-tooltip>                  
                  </a-typography-text>
                </template>

              </template>
            </a-table>
          </div>
          <a-skeleton active class="loading" v-else />
          <div style="width: 100%; height: 300px; background-color: #FFFFFF; position: relative;"></div>
        </a-tab-pane>
        <a-tab-pane key="const-speed" :tab="$t('fleet-page.const speed')" >
          <div class="table"  v-if="!isLoading" >
            <a-table style="font-size: 12px"
                     class="table-src-route"
                     :columns="columns"
                     :scroll="{ x: '100%', y: 'calc(100vh - 535px)' }"
                     :data-source="dataSourceConstSpeed.list"
                     :pagination="false"
            >
              <template #headerCell="{ column }">
                <template v-if="column.key === 'id'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'name'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'coords'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'eta'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'rpm'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'speed'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'wind'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'swave'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
                <template v-if="column.key === 'scurrent'">
                  <span class="table-head">{{ column.title }}</span>
                </template>
              </template>

              <template #bodyCell="{ column, record }">
                <template v-if="column.dataIndex === 'id'">
                  <a-typography-text type="secondary">
                    {{ record.value.id }}
                  </a-typography-text>
                </template>

                <template v-if="column.dataIndex === 'name'">
                  <a-typography-text v-if="record.value.name" type="primary">
                    {{ record.value.name }}
                  </a-typography-text>
                  <a-typography-text v-else type="secondary">
                    Undefined
                  </a-typography-text>
                </template>

                <template v-if="column.dataIndex === 'coords'">
                  <a-typography-text type="primary">
                    {{ kFormatter.formatCoordinatesLat(record.value.point_data.geometry.coordinates[1])}}
                  </a-typography-text>
                  <br/>
                  <a-typography-text type="primary">
                    {{ kFormatter.formatCoordinatesLon(record.value.point_data.geometry.coordinates[0])}}
                  </a-typography-text>
                </template>

                <template v-if="column.dataIndex === 'eta'">
                  <a-typography-text type="primary">
                    {{  date.formatUTC(record.value.point_data.properties.eta) + " UTC"}}
                  </a-typography-text>
                </template>
                <template v-if="column.dataIndex === 'rpm'">
                  <a-typography-text type="primary">
                    {{ record.value.point_data.properties.rpm }}
                  </a-typography-text>
                </template>
                <template v-if="column.dataIndex === 'speed'">
                  <a-typography-text type="primary">
                    {{ record.value.point_data.properties.speed }}
                  </a-typography-text>
                </template>
                <template v-if="column.dataIndex === 'wind'">
                  <img :src="icons.arrowWind" alt="arrowWind" :style="rotate(record.value.point_data.properties.weather.wind.relative_direction) + '; margin-right: 5px; margin-bottom: 7px;'"/>
                  <a-typography-text type="primary">
                    {{ Math.round(record.value.point_data.properties.weather.wind.true_direction * 10)/ 10 + "° "+  Math.round(record.value.point_data.properties.weather.wind.true_speed * 10)/ 10 + " m/s" }}
                  </a-typography-text>
                    <a-tooltip>
                      <template #title>{{ record.value.point_data.properties.weather.wind.tooltip }}</template>
                      <img :src="icons.warningYellow" alt="warningYellow" v-if="record.value.point_data.properties.weather.wind.dangerous == 1" style="margin-left: 10px; margin-bottom: 2px;"/>
                      <img :src="icons.warningRed" alt="warningRed" v-if="record.value.point_data.properties.weather.wind.dangerous == 2" style="margin-left: 10px; margin-bottom: 2px;"/>
                    </a-tooltip>                  
                </template>
                <template v-if="column.dataIndex === 'swave'">
                  <img :src="icons.arrowWind" alt="arrowWind" :style="rotate(record.value.point_data.properties.weather.wave.relative_direction) + '; margin-right: 5px; margin-bottom: 7px;'"/>
                  <a-typography-text type="primary">
                    {{ Math.round(record.value.point_data.properties.weather.wave.true_direction * 10)/ 10 + "° " +  Math.round(record.value.point_data.properties.weather.wave.height * 10)/ 10 + " m" }}
                  </a-typography-text>
                  <a-tooltip>
                      <template #title>{{ record.value.point_data.properties.weather.wave.tooltip }}</template>
                      <img :src="icons.warningYellow" alt="warningYellow" v-if="record.value.point_data.properties.weather.wave.dangerous == 1" style="margin-left: 10px; margin-bottom: 2px;"/>
                      <img :src="icons.warningRed" alt="warningRed" v-if="record.value.point_data.properties.weather.wave.dangerous == 2" style="margin-left: 10px; margin-bottom: 2px;"/>
                  </a-tooltip>                  
                </template>
                <template v-if="column.dataIndex === 'scurrent'">
                  <img :src="icons.arrowWind" alt="arrowWind" :style="rotate(record.value.point_data.properties.weather.current.relative_direction) + '; margin-right: 5px; margin-bottom: 7px;'"/>
                  <a-typography-text type="primary">
                    {{ Math.round(record.value.point_data.properties.weather.current.true_direction * 10)/ 10 + "° " +  Math.round(record.value.point_data.properties.weather.current.true_speed * 10)/ 10 + "kn" }}
                    <a-tooltip>
                      <template #title>{{ record.value.point_data.properties.weather.current.tooltip }}</template>
                      <img :src="icons.warningYellow" alt="warningYellow" v-if="record.value.point_data.properties.weather.current.dangerous == 1" style="margin-left: 10px; margin-bottom: 2px;"/>
                      <img :src="icons.warningRed" alt="warningRed" v-if="record.value.point_data.properties.weather.current.dangerous == 2" style="margin-left: 10px; margin-bottom: 2px;"/>
                    </a-tooltip>                  
                  </a-typography-text>
                </template>

              </template>
            </a-table>
          </div>
          <a-skeleton active class="loading" v-else />
          <div style="width: 100%; height: 300px; background-color: #FFFFFF; position: relative;"></div>
        </a-tab-pane>
        <a-tab-pane key="map" :tab="$t('fleet-page.map')">
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
  <div v-if="isLoading" style="margin-top: 25%; margin-left: 40%; display: inline-grid; width: 250px;">
    <a-spin :indicator="indicator" :tip="$t('fleet-page.optimizing')" />    
    <a-typography-text type="secondary" v-if="state.progress_percent" style="font-weight: 300; text-align: center">
      {{ "Progress: " + (state.progress_percent) + "%" }}</a-typography-text>    
    <a-typography-text type="secondary" v-if="state.progress_description" style="font-weight: 300; text-align: center">
      {{ state.progress_description }}</a-typography-text>
    <a-button class="cancel" @click="cancelProcessingOptimization">
      {{$t('fleet-page.buttons.cancel')}}
    </a-button>
  </div>
  <!-- <a-skeleton active class="loading" /> -->
</template>

<script>
import {
  watchEffect,
  reactive,
  onMounted,
  computed,
  ref,
  nextTick,
  defineComponent,
  h,
} from "vue";

import { useRouter, useRoute } from "vue-router";
import { CloudUploadOutlined } from "@ant-design/icons-vue";
import { LoadingOutlined, FileOutlined } from '@ant-design/icons-vue';
import { useI18n } from "vue-i18n";
import { Modal, notification, Button  } from 'ant-design-vue';
import { useLoading } from "@/composables/useLoading";
import { date } from "@/helpers";
import { kFormatter } from "@/helpers";
import mapboxgl from "mapbox-gl";
import { icons } from "@/assets";
import { VueEcharts } from "vue3-echarts";
// import { format } from "date-fns";
import  useRouteOptimization from "@/composables/useRouteOptimization";
import "mapbox-gl/dist/mapbox-gl.css";

export default defineComponent({
  name: "FleetOptimizedRoute",

  props: {
    cancelPoling: {
      type: Boolean,
      default: () => false,
    },
  },

  setup(props) {
    



    console.log("FleetOptimizedRoute");    
    console.log("route_geo_json", props.route_geo_json);

    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '50px',
        // zindex: 0,
        // position: absolute
      },
      spin: true,
    });


    const route = useRoute();
    const router = useRouter();
    console.log(route.params.imo);
    const loading = useLoading();
    const routeOptimization = useRouteOptimization();
    const dataSourceConstRpm = reactive({ list: [] });
    const dataSourceConstPower = reactive({ list: [] });
    const dataSourceConstSpeed = reactive({ list: [] });

    const state = reactive({
      map: null,
      // value: 0,
      etd: props.etd,
      eta: props.eta,
      route_geo_json: null,
      imo: route.params.imo,

      waypoints: 0,
      distance: null,
      load_condition: null,
      arrival_port: null,
      departure_port: null,
      cancelPoling: props.cancelPoling,
      routes_rtz: null,
      progress_percent: null,
      progress_description: null

      // active_mode: "const-rpm",
    });


  const openNotificationWithIcon = () => {
    notification['success']({
      message: 'Voyage route optimization complete',
      description: 'Voyage route optimization complete',
      btn: () =>
      h(
        Button,
        {
          type: 'primary',
          size: 'small',
          duration: 10,
          onClick: () => {
            notification.close()
            router.push({
                      name: "Fleet",
                      params: {
                        imo: state.imo,
                        tab: "route-optimizer",
                        filter: "optimized-route",
              },
            });
          },
        },
        { default: () => 'View' },
      ),
    });
  };

  
  const openErrorNotificationWithIcon = (errorDescription) => {
    notification['error']({
      message: 'Voyage route optimization error',
      description: errorDescription,
      btn: () =>
      h(
        Button,
        {
          type: 'primary',
          size: 'small',
          duration: 10,
          onClick: () => {
            notification.close()
            router.push({
                      name: "Fleet",
                      params: {
                        imo: state.imo,
                        tab: "route-optimizer",
                        filter: "route-for-optimize",
              },
            });
          },
        },
        { default: () => 'View' },
      ),
    });
  };

  const cancelProcessingOptimization = () => {    
    let response = routeOptimization.cancelOptimizingRoute(Number(state.imo));
    console.log("response", response);
    state.cancelPoling = true;
    router.push({
                      name: "Fleet",
                      params: {
                        imo: state.imo,
                        tab: "route-optimizer",
                        filter: "route-for-optimize",
              },
          });
  }

  async function getOptimized() {
    let response = null;
    if(route.params.id)
    {
      response = await routeOptimization.getOptimizationResult(route.params.id);
      state.voyage_hash = route.params.id;
    }
    else
    {
       response = await routeOptimization.getLastOptimized(state.imo);
    }

    state.progress_percent = response.payload?.progress;
    state.progress_description = response.payload?.description
    console.log("current status", response.status)      
    console.log("progress", response.payload?.progress)  
    console.log("description", response.payload?.description )
    switch (response.status) {
      case 'completed':
        if(state.cancelPoling){
          break;
        }    
        console.log("poling stopped")                 

        // console.log("response", response);
        // if(route.params.id)
        // {
        //   state.route_geo_json = response.payload.raw_data;
        // }
        // else
        // {
          state.route_geo_json = response.payload.raw_data; 
        // }  
        state.routes_rtz = response.payload.routes_rtz;   
        state.voyage_hash = response.payload.voyage_hash;
        state.progress_percent = null;
        state.progress_description = null;
              

        loading.finish(); 
        if(state.route_geo_json){
          console.log("route.params.filter", route.params.filter)
          if(route.params.filter != "optimized-route"){
            openNotificationWithIcon();
          }

          initialAfterLoad();           

          initMap();
          
          console.log("postpoling init complete")   
        }
        else{
          showError('Voyage Plan Alert', 'Error occured on optimize route. Check params or route file');
          console.log(response.payload);
          router.push({
                      name: "Fleet",
                      params: {
                        imo: state.imo,
                        tab: "route-optimizer",
                        filter: "route-for-optimize",
              },
          });        
        }


        
        break;
      case 'not_found':      
        loading.finish(); 
        console.log("Optimized route not found", response.payload);
        // showMessage()
        router.push({
                      name: "Fleet",
                      params: {
                        imo: state.imo,
                        tab: "route-optimizer",
                        filter: "route-for-optimize",
              },
            });

        break;
      case 'error':
        
        loading.finish();
        var errorMsg = response?.description ?? 'Error occured on optimize route';
        if(route.params.filter != "optimized-route"){
            openErrorNotificationWithIcon(errorMsg);
          }
        else{            
          showError('Voyage Plan Alert', errorMsg);
          console.log(response.payload);
          router.push({
                      name: "Fleet",
                      params: {
                        imo: state.imo,
                        tab: "route-optimizer",
                        filter: "route-for-optimize",
              },
            });
        }
                
        break;
      default: //pending?
        if(state.cancelPoling){
          break;
        }    
        await new Promise(resolve => setTimeout(resolve, 3000));
        await getOptimized();
        break;
    }
  }




    onMounted(() => {
      loading.start();
      getOptimized();  
    });

    const rotate = (angle) => {
      return "transform: rotate(" + Math.trunc(angle) + "deg)";
    }



    const initialAfterLoad = async () => {
      
      let start = 1;
      const coordinatesConstRpm = state.route_geo_json.features[0].map((item) => ref({
        id: start++,
        point_data: item,
        name: item.properties.waypoint_name
      }));
      start = 1
      const coordinatesConstPower = state.route_geo_json.features[1].map((item) => ref({
        id: start++,
        point_data: item,
        name: item.properties.waypoint_name
      }));
      start = 1
      const coordinatesConstSpeed = state.route_geo_json.features[2].map((item) => ref({
        id: start++,
        point_data: item,
        name: item.properties.waypoint_name
      }));

      if (!dataSourceConstRpm.list.length) {
        dataSourceConstRpm.list = coordinatesConstRpm;
      }

      if (!dataSourceConstPower.list.length) {
        dataSourceConstPower.list = coordinatesConstPower;
      }

      if (!dataSourceConstSpeed.list.length) {
        dataSourceConstSpeed.list = coordinatesConstSpeed;
      }

      state.waypoints = state.route_geo_json.properties.route_info.waypoints;
      state.distance = state.route_geo_json.properties.route_info.distance;
      state.load_condition = state.route_geo_json.properties.route_info.load_condition;
      state.departure_port = state.route_geo_json.properties.route_info.departure_port;
      state.arrival_port = state.route_geo_json.properties.route_info.arrival_port;
      state.eta = state.route_geo_json.properties.route_info.eta;
      state.etd = state.route_geo_json.properties.route_info.etd;

    }

    const initMap = async () => {
      console.log("init map", state.route_geo_json);
      
      const features = state.route_geo_json.features[0];
      const coordinates = features.map((item) => [item.geometry.coordinates[0] < 0 ? item.geometry.coordinates[0] + 360 : item.geometry.coordinates[0], item.geometry.coordinates[1]]);      
      const result = [];
      let lastElement = 0;
      let resultCount = [];

      let shift = 0.;
      let lon_previous = features[0].geometry.coordinates[0];
      
      for (let i = 0; i < features.length; i++) {
        const el = features[i].properties?.weight;

        let current = features[i].geometry.coordinates[0];
          let lon_current = current + shift;
          if (lon_current - lon_previous > 180) {
              lon_current -= 360;
              shift -= 360;
          } else if (lon_previous - lon_current > 180) {
              lon_current += 360;
              shift += 360;
          }
          lon_previous = lon_current;

        let coordinates3 = [lon_current ,features[i].geometry.coordinates[1]];

        if (lastElement === 0) {
          lastElement = el;
        } else if (
            (el > 4 && lastElement <= 4) ||
            (el <= 4 && lastElement > 4)
        ) {
          resultCount.push(coordinates3);

          result.push({
            type: lastElement > 4 ? "#F03945" : "#81D862",
            data: resultCount,
          });

          resultCount = [];
          lastElement = el;
        }

        resultCount.push(coordinates3);
      }

      if (resultCount.length) {
        result.push({
          type: lastElement > 4 ? "#F03945" : "#47BF2F",
          data: resultCount,
        });
      }

      
    await nextTick();

      if (!state.map) {
        console.log("state.map")
        state.map = new mapboxgl.Map({
          container: "rtmapcontainer",
          style: process.env.VUE_APP_MAPBOX_STYLE,
          center: coordinates[0],
          zoom: 1,
        });

        state.map.on("load", () => {
          state.map.addSource("points", {
            type: "geojson",
            lineMetrics: true,
            data: {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: coordinates,
              },
            },
          });

          state.map.addSource("directionPoints", {
            type: "geojson",
            data: {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: [
                  coordinates[0],
                  coordinates[coordinates.length - 1],
                ],
              },
            },
          });

          result.map((item, i) => {
            state.map.addLayer({
              id: `points-${i}`,
              type: "line",
              source: {
                type: "geojson",
                lineMetrics: true,
                data: {
                  type: "Feature",
                  geometry: {
                    type: "LineString",
                    coordinates: item.data,
                  },
                },
              },
              layout: {
                "line-join": "round",
                "line-cap": "round",
              },
              paint: {
                "line-color": item.type,
                "line-width": 2,
              },
            });
          });

          state.map.addLayer({
            id: "directionPoints",
            type: "circle",
            source: "directionPoints",
            paint: {
              "circle-color": "transparent",
              "circle-radius": 5,
              "circle-stroke-width": 2,
              "circle-stroke-color": "#47BF2F",
            },
          });
        });
      }

      await nextTick();

      if (state.map) {
        state.map.resize();

        state.map.fitBounds(
            [coordinates[0], coordinates[coordinates.length - 1]],
            { padding: 50 }
        );

        state.map.resize();
      }
    };


    const chartRef = ref(null);

    const chart = reactive({
      option: {
        grid: {
          top: "12%",
          left: "7%",
          right: "4%",
          bottom: "7%",
        },
        color: ["#5470C6", "#91CC75","#EE6666"],
        tooltip: {
          trigger: "axis",
        },
        // toolbox: {
        //   feature: {
        //     // dataView: { show: true, readOnly: false },
        //     restore: { show: true },
        //     saveAsImage: { show: true },
        //   },
        // },
        legend: {
          data: ["Const rpm", "Const power", "Const speed"],
        },
        // xAxis: [
        //   {
        //     splitLine: {
        //       show: true,
        //       lineStyle: {
        //         type: "dashed",
        //         width: 1,
        //         color: "#999",
        //       },
        //     },
        //     type: "category",
        //     boundaryGap: true,
        //     axisLabel: {
        //       formatter: function (value) {
        //         return value;
        //       },
        //     },
        //     // prettier-ignore
        //     // data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        //   },
        // ],
        yAxis: [
          {
            boundaryGap: true,
            type: "value",
            name: "SOG",
            position: "left",
            alignTicks: true,
            axisLine: {
              show: false,
              lineStyle: {
                color: "#5470C6",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                // width: 1,
                color: "#4B4C53",
              },
            },
            // axisLine: { onZero: false },
            axisLabel: {              
              formatter: "{value} kt",
              show: true,
              color: "#666971",
              fontSize: 12,
            },
            
            // data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
          },
        ],
        dataZoom: [
          {
            type: "inside",
          },
          {
            type: "slider",
          },
        ],
        
      },
    });

    const chartOption = computed(() => chart.option);


    watchEffect(() => {   
      if (state.route_geo_json?.features ){    
        
        const coordinatesConstRpm = state.route_geo_json.features[0].map((item) => ref({
          eta: item.properties.eta,
          speed: item.properties.speed
        }));

        const coordinatesConstPower = state.route_geo_json.features[1].map((item) => ref({
          eta: item.properties.eta,
          speed: item.properties.speed
        }));

        const coordinatesConstSpeed = state.route_geo_json.features[2].map((item) => ref({
          eta: item.properties.eta,
          speed: item.properties.speed
        }));

        const etaXaxis = [];
        const speedConstRpm = [];        
        const speedConstPower = [];
        const speedConstSpeed = [];

        for (let i = 0; i < state.waypoints; i++) {
          etaXaxis.push(coordinatesConstRpm[i].value.eta)  
          if(i != state.waypoints - 1){          
            etaXaxis.push(coordinatesConstRpm[i].value.eta)
          }
          speedConstRpm.push(coordinatesConstRpm[i].value.speed)
          speedConstPower.push(coordinatesConstPower[i].value.speed)
          speedConstSpeed.push(coordinatesConstSpeed[i].value.speed)

          if(i != 0){   
            speedConstRpm.push(coordinatesConstRpm[i].value.speed)
            speedConstPower.push(coordinatesConstPower[i].value.speed)
            speedConstSpeed.push(coordinatesConstSpeed[i].value.speed)
          }
        }
        

        // const onlySpeedRpm = state.route_geo_json.features[0].map((item) => 
        //   Math.round(item.properties.speed * 10)/ 10
        // )
        
        // const onlySpeedPower = state.route_geo_json.features[1].map((item) => 
        //   Math.round(item.properties.speed * 10)/ 10
        // )
        
        // const onlySpeedSpeed = state.route_geo_json.features[0].map((item) => 
        //   Math.round(item.properties.speed * 10)/ 10
        // )
        // const maxConstRpmSpeed = Math.max(...onlySpeedRpm);
        // const minConstRpmSpeed = Math.min(...onlySpeedRpm);
        
        // console.log("maxConstRpmSpeed", maxConstRpmSpeed);
        // console.log("minConstRpmSpeed", minConstRpmSpeed);

        // const maxConstPowerSpeed = Math.max(...onlySpeedPower);
        // const minConstPowerSpeed = Math.min(...onlySpeedPower);
        
        // console.log("maxConstPowerSpeed", maxConstPowerSpeed);
        // console.log("minConstPowerSpeed", minConstPowerSpeed);

        // const maxConstSpeedSpeed = Math.max(...onlySpeedSpeed);
        // const minConstSpeedSpeed = Math.min(...onlySpeedSpeed);
        
        // console.log("maxConstSpeedSpeed", maxConstSpeedSpeed);
        // console.log("minConstSpeedSpeed", minConstSpeedSpeed);

        // const maxTotalSpeed = Math.max(maxConstRpmSpeed, maxConstPowerSpeed, maxConstSpeedSpeed, minConstRpmSpeed, minConstPowerSpeed, minConstSpeedSpeed)
        // const minTotalSpeed = Math.max(minConstRpmSpeed, minConstPowerSpeed, minConstSpeedSpeed, maxConstRpmSpeed, maxConstPowerSpeed, maxConstSpeedSpeed)

        // console.log("maxTotalSpeed", maxTotalSpeed);
        // console.log("minTotalSpeed", minTotalSpeed);
        
        chart.option.yAxis[0].data = [];
        // chart.option.yAxis[0].data = [0, minTotalSpeed - 1.5, minTotalSpeed - 0.5, minTotalSpeed, maxTotalSpeed, maxTotalSpeed + 0.5, maxTotalSpeed + 1.5];
        // [
        //   {
        //     boundaryGap: true,
        //     // type: "value",
        //     name: "Speed",
        //     // position: "right",
        //     alignTicks: true,
        //     axisLine: {
        //       show: false,
        //       lineStyle: {
        //         color: "#5470C6",
        //       },
        //     },
        //     splitLine: {
        //       show: true,
        //       lineStyle: {
        //         type: "dashed",
        //         // width: 1,
        //         color: "#4B4C53",
        //       },
        //     },
        //     // axisLine: { onZero: false },
        //     axisLabel: {
        //       show: true,
        //       color: "#666971",
        //       fontSize: 12,
        //     },
        //     data: [0, minTotalSpeed - 1.5, minTotalSpeed - 0.5, minTotalSpeed, maxTotalSpeed, maxTotalSpeed + 0.5, maxTotalSpeed + 1.5]
        //     // data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
        //   },
        // ],

        chart.option.xAxis = 
        [
          {
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                width: 1,
                color: "#999",
              },
            },
            type: "category",
            boundaryGap: true,
            axisLabel: {
              formatter: function (value) {
                return value;
              },
            },
            // prettier-ignore
            // data: coordinatesConstRpm.map((item) => date.formatUTC(item.value.eta)),
            data: etaXaxis.map((item) => date.formatUTC(item)),
          },
        ],



        // chart.option.xAxis.data = 
        
        console.log("chart.option.xAxis.data", chart.option.xAxis.data)

        // chart.option.yAxis.data = coordinatesConstRpm.map(
        //   (item) => [item.value.point_data.properties.speed]
        // );

        chart.option.series = [
          {
            name: "Const rpm",            
            smooth: false,
            type: "line",
            // stack: "ad",
            // backgroundColor: "#4D86FF",
            // itemStyle: {
            // normal: { areaStyle: { type: "default" } },
            // },
            // data: coordinatesConstRpm.map((item) =>  item.value.speed)
            data: speedConstRpm
          },
          {
            name: "Const power",            
            smooth: false,
            type: "line",
            // stack: "ad",
            // yAxisIndex: 1,
            // data: coordinatesConstPower.map((item) => item.value.speed)
            data: speedConstPower
          },          
          {
            name: "Const speed",            
            smooth: false,
            type: "line",
            // stack: "ad",
            // yAxisIndex: 1,
            // data: coordinatesConstSpeed.map((item) => item.value.speed)
            data: speedConstSpeed
          }
        ];





        if (chartRef.value) {
          chartRef.value.refreshOption();
        }
      }
    });

    const showError = (title, content) => {      
      Modal.error({
        title: () => title,
        content: () => content,
      });
    }

    const saveOptimized = (mode) => {
      routeOptimization.saveOptimizationResult(state.voyage_hash, mode).then((response) => {
        if(response.status == "error"){
            notification['error']({
            message: 'Voyage Plan Alert',
            description: 'Optimized route NOT saved',        
          });
        }
        else{
            notification['success']({
            message: 'Success',
            description: 'Optimized route saved',        
          });
        }        
      })
    }
    const exportOptimized = (index) => {
      var result = {
        type: "FeatureCollection",
        features: state.route_geo_json.features[index]
      }
      var extension = null;
      var file = null;
      if(state.routes_rtz.const_rpm != '' && state.routes_rtz.const_rpm != '' && state.routes_rtz.const_rpm != ''){
        var const_rpm_rtz = window.atob(state.routes_rtz.const_rpm);
        console.log(const_rpm_rtz);
        var const_power_rtz = window.atob(state.routes_rtz.const_rpm);
        console.log(const_power_rtz);
        var const_speed_rtz = window.atob(state.routes_rtz.const_rpm);
        console.log(const_speed_rtz);

        switch (index){
          case 0:
            file = new Blob([const_rpm_rtz], {type: "application/xml"});
          break;
          case 1:            
            file = new Blob([const_power_rtz], {type: "application/xml"});
          break;
          case 2:
            file = new Blob([const_speed_rtz], {type: "application/xml"});
          break;
        }
        extension = ".rtz"
      }
      else
      {
        file = new Blob([JSON.stringify(result)], {type: "application/json"});        
        extension = ".geojson"
      }

      var a = document.createElement("a"),
          url = URL.createObjectURL(file);
      a.href = url;
      switch (index){
        case 0:
          a.download =  "optimized-route-const-rpm" + extension;
        break;
        case 1:
          a.download =  "optimized-route-const-power" + extension;
        break;
        case 2:
          a.download =  "optimized-route-const-speed" + extension;
        break;
        default:
        break;      
      }
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    };
    const { t } = useI18n();
    const columns = ref([
          {
            title: t('fleet-page.WPT'),
            dataIndex: "id",
            key: "id",
            width: "5%",
          },
          {
            title: t('fleet-page.NAME'),
            dataIndex: "name",
            key: "name",
            width: "18%",
          },
          {
            title: t('fleet-page.COORDINATES'),
            dataIndex: "coords",
            key: "coords",
            width: "11%",
          },
          {
            title: t('fleet-page.ETA'),
            dataIndex: "eta",
            key: "eta",
            width: "15%",
          },
          {
            title: t('fleet-page.RPM'),
            dataIndex: "rpm",
            key: "rpm",
            width: "6%",
          },
          {
            title: t('fleet-page.SPEED'),
            dataIndex: "speed",
            key: "speed",
            width: "6%",
          },
          {
            title: t('fleet-page.WIND'),
            dataIndex: "wind",
            key: "wind",
            width: "13%",
          },
          {
            title: t('fleet-page.SIGN WAVE'),
            dataIndex: "swave",
            key: "swave",
            width: "13%",
          },
          {
            title: t('fleet-page.SIGN CURRENT'),
            dataIndex: "scurrent",
            key: "scurrent",
            width: "13%",
          },
    ]);

    return {
      route,
      router,
      rotate,
      chartOption,
      indicator,
      chartRef,
      icons,
      kFormatter,     
      routeOptimization,
      state,
      dataSourceConstRpm,
      dataSourceConstPower,
      dataSourceConstSpeed,
      date,
      columns,
      initialAfterLoad,
      initMap,
      isLoading: loading.active,
      exportOptimized,
      saveOptimized,
      cancelProcessingOptimization
    };
  },

  components: {
    VueEcharts,
    CloudUploadOutlined,
    FileOutlined
    // DeleteOutlined,
  },
});
</script>

<style lang="less" scoped>
.ps {
  height: 100%;
}


.destination{
  background-color: #F5F7FA;
  //padding-left: 10%;
  margin-left: 2%;
  //margin-right: 60%;
  margin-top: 1%;
  // margin-bottom: 2%;
  height: 60px;
  width: 700px;
  //padding-top: 0.7%;
  border: @color-primary;
  border-width: 1px;
  border-style: solid;
}

.import-route-file {
  color: @color-black;
  border-color: @color-border;
  background-color: @color-value-bg;
  margin-left: 10%;
  width: 80%;

}

.export {
  color: @color-white;
  border-color: @color-border;
  background-color: @color-primary-light;
  width: 115px;
  height: 34px;
  margin-left: 24px;
  margin-top: 12px
}
.reset {
  color: @color-black;
  border-color: @color-border;
  background-color: @color-secondary;
  width: 115px;
  height: 34px;
  margin-left: 24px;
  margin-top: 12px
}

//.table-src-route{
//  height:70%;
//  display:block;
//  font-size: 12px;
//}
.header {
  //height: 8.4rem;
  display: flex;
  align-items: center;
  //justify-content: space-between;
  padding: 1.55rem 2.8rem 0.55rem 2.8rem;

  &-heading {
    display: flex;
    align-items: center;
    gap: 3.2rem;

    h2.ant-typography {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      font-size: 21px;
      color: @color-black;

      //
      //.ant-typography-secondary {
      //  font-size: 1.8rem;
      //  font-weight: 400;
      //}
    }
  }

  &-back {
    img {
      height: 1.6rem;
    }

    &:hover {
      opacity: 0.5;
    } 
  }

  &-actions {
    .export-report {
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}

.ant-radio-group:deep {
  .ant-radio-button-wrapper {
    width: 50%;
    text-align: center;
    &:not(.ant-radio-button-wrapper-checked){
      border-color: @input-border-color;
    }
  }
}

.table {
  // height: calc(100vh - 450px);
  width: 100%;
  display: flex;

  // &-src-route{
  //   // height: calc(100vh - 350px);
  // // display:block;
  //   font-size: 12px;
  // }
}

.cancel {
  color: @color-white;
  border-color: @color-border;
  background-color: @color-primary-light;
  //width: 145px;
  height: 34px;
  //left: -25px;
  display: block;
  // margin-left: 24px;
  margin-top: 12px
}

.optimized {
  width: 100%;
  //display: flex;

  &-top {
    width: 100%;
    height: 25%;
    background-color: @color-white;    
  }
  
  &-map {
      width: 100%;
      height: 100%;
      position: fixed;
      overflow: hidden;

      #rtmapcontainer {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0;
        left: 0;
      }
    }

  &-bottom {
    width: 100%;
    height: 70%;
    //position: relative;
    //background: @color-border

  }
  
}

</style>
