<template>
  <router-view />
</template>

<style lang="less">
//@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

html {
  font-size: 62.5%; // 1rem = 10px
}

#app{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  user-select: none;
}

.text-block {
    white-space: pre; // or pre-line
}

input {
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #2c2d31 inset !important;
    -webkit-text-fill-color: white !important;
  }
}

.ant-input-password {
  .ant-input {
    border-radius: 0px;
  }
}

.ant-radio-group {
  .ant-radio-button-wrapper {
    line-height: 3rem;
    border-width: 0.2rem;

    &-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper {
      &:first-child {
        border-right-color: transparent;
      }
    }
  }
}

.ant-select {
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-width: 0.2rem;
    }
  }

  &:not(.ant-select-disabled) {
    &:hover {
      .ant-select-selector {
        border-right-width: 0.2rem !important;
      }
    }
  }

  &-focused {
    &:not(.ant-select-disabled) {
      &.ant-select {
        &:not(.ant-select-customize-input) {
          .ant-select-selector {
            border-right-width: 0.2rem !important;
          }
        }
      }
    }
  }

  &-selection-item {
    line-height: 3rem !important;
  }

  &-selection-placeholder {
    line-height: 3rem !important;
  }

  &-arrow {
    color: @color-secondary;
  }
}

.ant-picker {
  border-width: 0.2rem;
  padding: 0.4rem 1.1rem 0.4rem;

  &:hover,
  &-focused {
    border-right-width: 0.2rem !important;
  }

  &-suffix {
    color: @color-secondary;
  }
}

.ant-input {
  border-width: 0.1rem;
  padding: 0.4rem 1.1rem;
  background-color: @color-white;

  &:hover,
  &:focus,
  &-focused {
    border-right-width: 0.2rem !important;
  }
}

.ps {
  &.ps--active-y > .ps__rail-y {
    opacity: 0.5;

    &:hover,
    &:focus {
      background-color: transparent;
      opacity: 0.7;
    }
  }
}

// .mapboxgl-control-container {
//   display: none;
// }

.mapboxgl-ctrl-top-left {
  top: 60px !important;
  left: 12px !important;
}

.my-custom-control {
  color: #f00;
  background: #000;
  padding: 8px;
  border-radius: 0px;
  margin: 8px;
}

.mapboxgl-ctrl-top-right {
  top: 60px !important;
  right: 12px !important;
}

.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib {
  display: none !important;
}

.mapboxgl-ctrl-group {
  background: none !important;
}

.mapboxgl-ctrl-group button {
  width: 52px !important;
  height: 52px !important;
  background: #27282c !important;
  margin-bottom: 5px;
}

.mapboxgl-ctrl-group button svg {
  fill: #9fa2ae;
}

.mapboxgl-ctrl-group button + button {
  border-top: none !important;
}

.ant-dropdown {
  &-menu {
    box-shadow: none;
  }
}

.ant-statistic {
  &-content {
    text-transform: uppercase;
    color: @text-color;
  }

  &.is-disabled {
    .ant-statistic-content {
      opacity: 0.3;
    }
  }

  &.statistic-large {
    .ant-statistic-content {
      line-height: 1;
      font-size: 5.4rem;

      .media-xl({
        font-size: 3.2rem;
      });
    }
  }

  &.statistic-mini {
    .ant-statistic-content {
      line-height: 1;
      font-size: 1.6rem;

      .media-xl({
        font-size: 1.2rem;
      });
    }
  }

  &.statistic-middle {
    .ant-statistic-content {
      line-height: 1;
      font-size: 4.2rem;

      .media-xl({
        font-size: 2.4rem;
      });
    }
  }

  &.statistic-largest {
    .ant-statistic-content {
      line-height: 1;
      font-size: 7.2rem;

      .media-xl({
        font-size: 3.6rem;
      });
    }
  }
}

.ant-tabs {
  &-top {
    .ant-tabs-nav {
      background-color: @color-white-panel;
      padding: 0 2rem;
      margin-bottom: 0;

      &:before {
        border-color: @color-border;
      }

      .ant-tabs-nav-wrap {
        .ant-tabs-tab {
          padding: 1.3rem 0;

          &-active {
            .ant-tabs-tab-btn {
              color: @color-primary-light;
              font-weight: 500;
            }
          }

          &:hover {
            .ant-tabs-tab-btn {
              color: @color-primary;
            }
          }
        }
      }

      .ant-tabs-ink-bar {
        height: 0.3rem;
        color: @color-primary-light;
      }
    }
  }
}

.ant-table-wrapper {
  .ant-table {
    .ant-table-container {
      border-left: 0;

      .ant-table-header {
        table {
          border-top: 0;
        }
      }

      .ant-table-thead {
        .ant-table-cell {
          font-weight: 400;
        }
      }

      .ant-table-tbody {
        .ant-table-row {
          &.table-row-selected {
            .ant-table-cell {
              background: rgba(@color-primary-light, 0.08);
            }
          }
        }
      }
    }
  }

  &.table-factor {
    .ant-table {
      .ant-table-container {
        border-left: 0;

        .ant-table-header {
          table {
            border-top: 0;
          }
        }

        .ant-table-thead {
          .ant-table-cell {
            border-color: @color-border;

            &:before {
              display: none;
            }

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 10;
            }
          }
        }

        .ant-table-tbody {
          .ant-table-row {
            &:last-child {
              .ant-table-cell {
                border-color: transparent;
              }
            }

            .ant-table-cell {
              background-color: @color-white;
              border-color: @color-border;

              &:first-child {
                padding-left: 0;
              }

              &:last-child {
                padding-right: 10;
              }
            }
          }
        }
      }
    }
  }

  &.table-benchmarking {
    .ant-table {
      .ant-table-container {
        .ant-table-tbody {
          .ant-table-row {
            .ant-table-cell {
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(6),
              &:nth-child(8),
              &:nth-child(10) {
                border-right: 0;
              }
            }
          }
        }
      }
    }
  }

  &.table-deliveries,
  &.table-history {
    width: calc(100% - 43.5rem);

    &.full {
      width: 100%;
    }

    .ant-table {
      .ant-table-container {
        .ant-table-tbody {
          .ant-table-row {
            .ant-table-cell {
              &:nth-child(3) {
                border-right: 0;
              }
            }
          }
        }
      }
    }
  }
}


.ant-picker-header{
  color: @color-black;
}

.ant-modal-confirm-body .ant-modal-confirm-title{
  color: @color-black;
}

.ant-notification-notice-with-icon .ant-notification-notice-message{
  color: @color-black;
}

.modal-report {
  .ant-modal-header {
    background-color: @color-white-panel;
    padding: 1.4rem 2.1rem;
  }

  .ant-modal-title {
    font-size: 2rem;
    font-weight: 700;
    color: @text-color;
    text-transform: uppercase;
  }

  .ant-modal-content {
    background-color: @color-body;
  }
}

.modal-delivery-add {
  .ant-modal-header {
    border: 0;
  }

  .ant-modal-title {
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .ant-modal-body {
    .ant-form {
      .ant-form-item {
        &-label {
          label {
            color: @color-secondary;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}


.ant-form {
  .ant-form-item {
    &-label {
      label {
        color: @color-secondary;
      }
    }
  }
}


.ant-radio-group {
  .ant-radio-button-wrapper-checked {
    &:not(.ant-radio-button-wrapper-disabled) {
      &:focus-within {
        box-shadow: none;
      }
    }
  }
}

h3.ant-typography {
  color: @text-color;
}
.dropdown-header-accounts {
  .ant-dropdown-menu {
    background-color: @color-body;
    box-shadow: 0 0.4rem 1.6rem rgba(@color-dark, 0.25);
    border: 0.1rem solid @color-border;

    &-item {
      .ant-dropdown-menu-title-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2.8rem;

        .ant-typography-secondary {
          text-transform: uppercase;
        }
      }
    }
  }
}
</style>